<template>
  <!-- :style="{ backgroundImage: `url(https://image.tmdb.org/t/p/w780/${featuredPick.backdropPath})`}" -->
  <div class="mt-8 md:mt-12">
    <div class="flex flex-col items-center space-y-4 px-6 md:px-16">
      <h1 class="text-3xl md:text-4xl font-bold text-center">Featured Pick</h1>
      <router-link
        to="/all-picks"
        class="text-gray-100 flex items-center space-x-1 border-b border-gray-200"
        ><span>See all picks</span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </router-link>
    </div>
    <section class="mt-10">
      <!-- TODO: make featured pick component -->
      <div
        class="px-6 md:px-16 flex justify-center items-center md:space-x-8 flex-wrap"
        v-if="featuredPick"
      >
        <div class="w-full md:w-auto">
          <img
            class="rounded-lg shadow max-w-xs mx-auto"
            :src="`https://image.tmdb.org/t/p/w780/${featuredPick.posterPath}`"
            :alt="`${featuredPick.title} poster image`"
          />
        </div>
        <div class="max-w-xs md:mt-0 w-full md:w-auto mt-6">
          <div class="mb-3">
            <div class="text-2xl font-bold mb-1">{{ featuredPick.title }}</div>
            <div class="mb-2 flex items-center space-x-3 text-lg">
              <span>
                {{ featuredPick.year }}
              </span>

              <div class="flex items-center space-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-300"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>

                <span>{{ featuredPick.userRating }}</span>
              </div>
              <div
                class="capitalize my-1"
                v-if="featuredPick.network !== 'other'"
              >
                {{ featuredPick.network }}
              </div>
              <div class="my-1" v-else>
                <a
                  class="flex space-x-1 items-center"
                  target="_blank"
                  :href="featuredPick.otherLink"
                  ><span>Link</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="grid gap-2 grid-flow-col auto-cols-max text-sm">
              <span
                v-for="genre in featuredPick.genres"
                :key="genre.id"
                class="bg-gray-300 text-gray-900 rounded px-1"
              >
                {{ genre.name }}
              </span>
            </div>
          </div>
          <div>{{ featuredPick.overview }}</div>
        </div>
      </div>
    </section>
    <section class="mt-20">
      <div class="flex items-center justify-between mb-6 px-6 md:px-16">
        <h2 class="text-xl md:text-xl font-bold">Recently added</h2>
        <router-link
          to="/all-picks"
          class="text-gray-100 flex items-center space-x-1 border-b border-gray-200 text-sm"
          ><span>See All</span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </router-link>
      </div>
      <div
        class="grid grid-cols-feature-four-column gap-6 overflow-x-auto px-6 md:px-16"
      >
        <MovieCard
          v-for="recentPick in recentPicks"
          :key="recentPick.id"
          :movie="recentPick"
          :showImages="true"
        ></MovieCard>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import MovieCard from '../components/MovieCard.vue';

export default {
  name: 'HomePage',
  components: { MovieCard },
  computed: {
    ...mapState({
      movies: (state) => state.movies,
    }),
    recentPicks() {
      return this.movies.slice(0, 4);
    },
    featuredPick() {
      return this.movies.find((movie) => movie.featured === true);
    },
  },
};
</script>
